import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SearchFiltersV2Actions } from '@store/search-filters-v2/index';
import { catchError, filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { getResolvedNetwork } from '@store/network/network.selectors';
import { of } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { SearchFiltersSettings } from '@components/+search/classes/search-filters-settings.class';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { SearchFiltersV2Service } from '@services/search/search-filters-v2.service';
import { SearchParams } from '@interfaces/search-params.model';
import { SearchFilterV2EffectData } from '@interfaces/search-filter-v2-effect-data.interface';
import { getSerpLiteConfigFilters } from './search-filters-v2.selectors';
import { getSearchType } from '@store/search/search.selectors';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';

@Injectable()
export class SearchFiltersV2Effects {

  getSearchFiltersV2FromConfig = createEffect(() =>
    this.actions.pipe(
      ofType(SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfig),
      withLatestFrom(this.store.select(getResolvedNetwork)),
      mergeMap(([_setting, network]) =>
        this.settingsService.getSetting('search_filters_v2').pipe(
          take(1),
          map((searchFilters: any) => {
            return SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfigSuccess(
              {
                filters: searchFilters ?
                  this.searchFiltersSettings.mapSearchFiltersV2Config(searchFilters, network) :
                  [],
              }
            );
          }),
          catchError((error) =>
            of(
              SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfigFailure(
                {
                  error,
                }
              )
            )
          )
        )
      )
    )
  );

  applyFacetDataToFilters = createEffect(() =>
    this.actions.pipe(
      ofType(SearchFiltersV2Actions.requestFacets),
      mergeMap(action => this.searchFiltersV2Service.getFacetEffectData().pipe(map(data => [action.search_params, data]))),
      mergeMap(([searchParams, { mappedSearchType, configFilters, defaultFilters, sortConfig, radiusConfig }]: [SearchParams, SearchFilterV2EffectData]) => {
        return this.searchFiltersV2Service
          .getFiltersWithFacetData(
            cloneDeep(configFilters),
            searchParams,
            mappedSearchType,
            sortConfig,
            radiusConfig,
            defaultFilters
          )
          .pipe(
            map((filterData: any) => {
              return SearchFiltersV2Actions.setSearchFiltersWithFacetData({
                appliedFilters: filterData,
              });
            }),
            catchError((error: TypeError) =>
              of(
                SearchFiltersV2Actions.requestFacetsFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  applySerpLiteFacetDataToFilters = createEffect(() =>
    this.actions.pipe(
      ofType(SearchFiltersV2Actions.requestSerpLiteFacets),
      withLatestFrom(
        this.store.select(getSearchType),
        this.store.select(getSerpLiteConfigFilters)
          .pipe(filter((configFilters) => !!configFilters)),
      ),
      mergeMap(([action, searchParamType, configFilters]) => {
        return this.searchFiltersV2Service
          .getFiltersWithFacetData(
            cloneDeep(configFilters),
            action.search_params,
            searchParamType,
            [],
            new SearchFilterV2({}),
            {}
          )
          .pipe(
            map((filterData: any) => {
              return SearchFiltersV2Actions.setSearchFiltersWithSerpLiteFacetData({
                appliedFilters: filterData,
              });
            }),
            catchError((error: TypeError) =>
              of(
                SearchFiltersV2Actions.requestSerpLiteFacetsFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  applyBaseFacetDataToFilters = createEffect(() =>
    this.actions.pipe(
      ofType(SearchFiltersV2Actions.requestBaseFacets),
      mergeMap((action) =>
        this.searchFiltersV2Service
          .getFacetEffectData()
          .pipe(map((data) => [action.search_params, data]))
      ),
      mergeMap(
        ([
          searchParams,
          {
            mappedSearchType,
            configFilters,
            defaultFilters,
            sortConfig,
            radiusConfig,
          },
        ]: [SearchParams, SearchFilterV2EffectData]) => {
          const searchRouteType = this.routeUtilities.getSearchRouteState(
            this.router.url
          );
          if (
            searchRouteType === 'hospital_affiliations' ||
            searchRouteType === 'group_affiliations'
          ) {
            defaultFilters.group_affiliation_ids =
              searchParams.group_affiliation_ids ||
              defaultFilters.group_affiliation_ids;
            defaultFilters.hospital_affiliation_ids =
              searchParams.hospital_affiliation_ids ||
              defaultFilters.hospital_affiliation_ids;
          }
          const defaultFiltersWithSearchRadius = {
            ...defaultFilters,
            radius: searchParams.radius,
          };
          return this.searchFiltersV2Service
            .getFiltersWithFacetData(
              cloneDeep(configFilters),
              {
                ...searchParams,
                ...defaultFiltersWithSearchRadius,
              },
              mappedSearchType,
              sortConfig,
              radiusConfig,
              defaultFilters,
              true
            )
            .pipe(
              map((filterData: any) => {
                return SearchFiltersV2Actions.setSearchFiltersWithBaseFacetData(
                  {
                    baseFilters: filterData,
                  }
                );
              }),
              catchError((error: TypeError) =>
                of(
                  SearchFiltersV2Actions.requestBaseFacetsFailure({
                    error,
                  })
                )
              )
            );
        }
      )
    )
  );

  private routeUtilities = new RouteUtilities();

  constructor(
    private actions: Actions,
    private settingsService: SettingsService,
    private searchFiltersSettings: SearchFiltersSettings,
    private searchFiltersV2Service: SearchFiltersV2Service,
    private store: Store,
    private router: Router
  ) {}
}
