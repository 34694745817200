import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DOCUMENT } from '@angular/common';
import { CcssService } from '../../services/ccss/ccss.service';
import { PageAnimations } from '../../utilities/page.animations';
import { PageTransition } from '../../interfaces/page-transition.interface';
import { GatedEntryOverlayData } from '@interfaces/gated-entry-overlay-data.interface';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { GatedEntryService } from '../../services/gated-entry/gated-entry.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Breakpoints } from '@classes/breakpoints.class';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StorageUtilities } from '@utilities/storage.utilities';
import { LoginLinks } from '@interfaces/login-links.model';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-gated-entry-overlay',
  templateUrl: './gated-entry-overlay.component.html',
  styleUrls: ['./gated-entry-overlay.component.scss'],
  animations: PageAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class GatedEntryOverlayComponent implements OnInit, OnDestroy {
  public pageTransition: PageTransition = {
    page: 'home',
    direction: 'none',
  };
  public gatedEntryHomePageDisabled: Boolean;
  public pageHistory: string[] = [];
  private subscriptions: SubscriptionManager = new SubscriptionManager();
  private storage: StorageUtilities = new StorageUtilities();
  private suppressLogin: boolean = false;

  constructor(
    public ccss: CcssService,
    public breakpoints: Breakpoints,
    public media: MediaObserver,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: GatedEntryOverlayData,
    @Inject(DOCUMENT) private document: Document,
    private sheetRef: MatBottomSheetRef<GatedEntryOverlayComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private gatedEntryService: GatedEntryService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.setLoginSuppress();
    this.pageHistory = this.storage.sessionStorageGet('gatedEntryPageHistory') || [];
    this.gatedEntryHomePageDisabled = this.data.gatedEntryConfig?.homeScreenDisabled || this.data.loggedIn;
    this.pageTransition = this.storage.sessionStorageGet('gatedEntryCurrentPage') || {
      ...this.setInitialScreen(this.data.startingPage),
    };
    this.gatedEntryService.gateOpened();
    this.disableBodyScroll();
    this.subscribeToExternalDismiss();
  }

  ngOnDestroy() {
    this.enableBodyScroll();
    this.gatedEntryService.gateClosed();
    this.subscriptions.destroy();
  }

  public onGoToOverlayPage(transition: PageTransition): void {
    this.pageTransition.direction = transition.direction || 'forward';
    // Force template to apply new animation state before changing page
    this.changeDetectorRef.detectChanges();
    this.pageHistory.push(this.pageTransition.page);
    this.pageTransition.page = transition.page;

    this.storage.sessionStorageSet('gatedEntryCurrentPage', this.pageTransition);
    this.storage.sessionStorageSet('gatedEntryPageHistory', this.pageHistory);
  }

  public onGoBack(): void {
    this.pageTransition.direction = 'back';
    this.changeDetectorRef.detectChanges();
    this.pageTransition.page = this.pageHistory.pop();

    this.storage.sessionStorageSet('gatedEntryCurrentPage', this.pageTransition);
    this.storage.sessionStorageSet('gatedEntryPageHistory', this.pageHistory);
  }

  public closeOverlay(): void {
    this.sheetRef.dismiss();
  }

  public showBackButton(): boolean {
    const onHomePage = this.pageTransition.page === 'home';
    const onStartingStep = this.pageTransition.page === 'network-location';
    return !(onHomePage || this.gatedEntryHomePageDisabled && onStartingStep);
  }

  private setInitialScreen(startingPage?: string): PageTransition {
    const page = startingPage || (this.gatedEntryHomePageDisabled || this.suppressLogin ? 'network-location' : 'home');
    return { page, direction: 'none' };
  }

  private disableBodyScroll(): void {
    this.getBodyElement().classList.add('no-scroll');
  }

  private enableBodyScroll(): void {
    this.getBodyElement().classList.remove('no-scroll');
  }

  private getBodyElement(): HTMLBodyElement {
    return this.document.getElementsByTagName('body')[0];
  }

  private subscribeToExternalDismiss(): void {
    this.subscriptions.add(
      this.getOnDismiss().subscribe(() => this.closeOverlay())
    );
  }

  private getOnDismiss(): Observable<boolean> {
    return this.gatedEntryService.onDismiss.pipe(filter((dismiss) => dismiss));
  }

  private setLoginSuppress(): void {
    this.subscriptions.add(
      this.settingsService
      .getSetting('login_links')
      .subscribe((links) => {
        this.suppressLogin = new LoginLinks(links).suppress_login;
      })
    );
  }

}
